import React from "react"

import { StimulusView } from "@views/stimulus"

export default () => {
  return (
    <StimulusView
      stimulus={{
        type: "Speech",
        originalSrc:
          "https://www.nobelprize.org/prizes/literature/1949/faulkner/speech/",
        src: "/stimulus/banquet-speech-by-william-faulkner/",
        author: "William Faulkner",
        title: "Banquet speech",
      }}
    >
      <section>
        <p>Ladies and gentlemen,</p>
        <p>
          I feel that this award was not made to me as a man, but to my work – a
          life’s work in the agony and sweat of the human spirit, not for glory
          and least of all for profit, but to create out of the materials of the
          human spirit something which did not exist before. So this award is
          only mine in trust. It will not be difficult to find a dedication for
          the money part of it commensurate with the purpose and significance of
          its origin. But I would like to do the same with the acclaim too, by
          using this moment as a pinnacle from which I might be listened to by
          the young men and women already dedicated to the same anguish and
          travail, among whom is already that one who will some day stand here
          where I am standing.
        </p>
        <p>
          Our tragedy today is a general and universal physical fear so long
          sustained by now that we can even bear it. There are no longer
          problems of the spirit. There is only the question: When will I be
          blown up? Because of this, the young man or woman writing today has
          forgotten the problems of the human heart in conflict with itself
          which alone can make good writing because only that is worth writing
          about, worth the agony and the sweat.
        </p>
        <p>
          He must learn them again. He must teach himself that the basest of all
          things is to be afraid; and, teaching himself that, forget it forever,
          leaving no room in his workshop for anything but the old verities and
          truths of the heart, the old universal truths lacking which any story
          is ephemeral and doomed – love and honor and pity and pride and
          compassion and sacrifice. Until he does so, he labors under a curse.
          He writes not of love but of lust, of defeats in which nobody loses
          anything of value, of victories without hope and, worst of all,
          without pity or compassion. His griefs grieve on no universal bones,
          leaving no scars. He writes not of the heart but of the glands.
        </p>
        <p>
          Until he relearns these things, he will write as though he stood among
          and watched the end of man. I decline to accept the end of man. It is
          easy enough to say that man is immortal simply because he will endure:
          that when the last dingdong of doom has clanged and faded from the
          last worthless rock hanging tideless in the last red and dying
          evening, that even then there will still be one more sound: that of
          his puny inexhaustible voice, still talking.
        </p>
        <p>
          I refuse to accept this. I believe that man will not merely endure: he
          will prevail. He is immortal, not because he alone among creatures has
          an inexhaustible voice, but because he has a soul, a spirit capable of
          compassion and sacrifice and endurance. The poet’s, the writer’s, duty
          is to write about these things. It is his privilege to help man endure
          by lifting his heart, by reminding him of the courage and honor and
          hope and pride and compassion and pity and sacrifice which have been
          the glory of his past. The poet’s voice need not merely be the record
          of man, it can be one of the props, the pillars to help him endure and
          prevail.
        </p>
      </section>
    </StimulusView>
  )
}
